.slick-slider {
    .slick-arrow {
        background: transparent;
        z-index: 9;
        color: initial;
        font-size: medium;
        line-height: normal;

        &::before {
            content: none;
        }
    }

    .slick-dots {
        & > li {
            content: none;
            margin: 0;
            margin-left: 6px;
            height: auto;

            &:first-child {
                margin-left: 0;
            }
            width: 24px;
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            &.slick-active {
                width: 24px;
                height: 3px;
                -webkit-transition: all 0.25s ease-in-out;
                -moz-transition: all 0.25s ease-in-out;
                -ms-transition: all 0.25s ease-in-out;
                -o-transition: all 0.25s ease-in-out;
                transition: all 0.25s ease-in-out;
                & > button {
                    width: 24px;
                    height: 3px;
                    padding: 0;
                    -webkit-transition: all 0.25s ease-in-out;
                    -moz-transition: all 0.25s ease-in-out;
                    -ms-transition: all 0.25s ease-in-out;
                    -o-transition: all 0.25s ease-in-out;
                    transition: all 0.25s ease-in-out;

                    ::before {
                        content: none;
                    }
                }
            }
            & > button {
                border-radius: 2px;
                width: 24px;
                height: 3px;
                padding: 0;
                content: none;
                background-color: #fff;
                border-radius: 6.25rem;

                &::before {
                    content: none !important;
                }
            }
        }
    }
}

.slick-slider.blog-slider {
    & .slick-list {
        margin: 0;
    }

    .slick-arrow {
        height: 60px;
        width: 60px;

        @media (max-width: 600px) {
            display: none !important;
        }
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        bottom: -30px;

        & > li {
            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }
}

.slick-slider.brands-slider {
    & .slick-list {
        margin: 0;
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        padding-top: 15px;
        position: static;

        & > li {
            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }
}

.product-pictures {
    .slick-dots {
        bottom: -20px;

        & > li {
            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }

    & > .slick-dots.slick-thumb {
        position: relative;
        bottom: -15px;
        & > li {
            width: 50px;
            height: 50px;
            position: relative;
            &.slick-active {
                transition: none;
                & > a {
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        height: 4px;
                        width: 100%;
                        background-color: #f1c8aa;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }

            & > a {
                display: block;
                width: 50px;
                height: 50px;
                position: relative;
                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.slick-slider.collections-slider {
    & .slick-list {
        margin: 0;

        & .slick-slide > div {
            padding: 10px 0;
        }
    }

    .slick-arrow {
        height: 60px;
        width: 60px;

        @media (max-width: 600px) {
            display: none !important;
        }
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        position: relative;
        bottom: 0;
        & > li {
            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }
}

.slick-slider.contents-home-slider {
    & .slick-list {
        margin: 0;

        & .slick-slide > div {
            padding: 10px 0;
        }
    }

    .slick-arrow {
        height: 60px;
        width: 60px;

        @media (max-width: 600px) {
            display: none !important;
        }
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        position: relative;
        bottom: -25px;
        & > li {
            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }
}
